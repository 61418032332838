@import './mixins/allimports.scss';

:global .phoneInputFix {
  width: 100%;

  .react-phone-number-input__country,
  .react-phone-number-input__country--native {
    display: none;
  }

  .react-phone-number-input__input,
  .react-phone-number-input__phone {
    border: 1px solid $primary-color;
    border-radius: 0;

    text-align: center;
    color: $gray-color;
    font-size: 12px;

    width: 100%;
    height: 24px;
  }

  &.phoneError {
    .react-phone-number-input__input,
    .react-phone-number-input__phone {
      border: 1px solid red;
      color: red;
    }
  }
}

:global .error,
:global .react-phone-number-input__error {
  color: red;
  text-align: center;
  font-size: 10px;
  pointer-events: none;
}

.form {
  position: relative;
  margin-top: 10px;
  width: 100%;

  .formRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .inputContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 49%;
      margin-bottom: 10px;
    }
  }

  .bottom {
    width: 100%;
    height: 80px;
    border: 1px solid $primary-color;
    border-radius: 0;
    background-color: $white-color;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &.bottomError {
      border: 1px solid red;

      textarea {
        color: red;
      }
    }

    .textArea {
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      border-bottom-style: groove;

      flex-grow: 1;
      resize: none;
      border: none;
      text-align: center;
      color: $gray-color;
      font-size: 12px;

      width: 100%;
      padding: 3px;

      &:focus {
        outline: none;
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100px;
      height: 24px;

      cursor: pointer;
      border-radius: 0;
      background: $primary-color;

      text-align: center;
      font-size: 12px;
      color: $white-color;
    }
  }

  .input {
    border: 1px solid $primary-color;
    border-radius: 0;

    text-align: center;
    color: $gray-color;
    font-size: 12px;
  }

  .input {
    width: 100%;
    height: 24px;
  }

  .formMessage {
    width: 100%;
    bottom: -40px;
    font-size: 14px;
    overflow: hidden;
    position: absolute;

    padding: 6px 12px;
    border-radius: 3px;
    border: 1px solid transparent;

    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.2s ease-in-out;

    .strong {
      font-size: 14px;
    }

    &.success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      animation: hide 0.2s 3s forwards;
    }

    &.danger {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
    }

    &.show {
      transform: scaleY(1);
    }
  }
}

@keyframes hide {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
