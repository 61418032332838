@import './mixins/allimports.scss';

$size: 35px;

.loader {
  transition: opacity $loader-speed ease-in-out;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    z-index: 1;
  }

  &.blue {
    .ldsRoller div:after {
      background: #98b2e6 !important;
    }
  }

  .ldsRoller {
    transform: scale(0.8);

    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    z-index: 2;
  }
  .ldsRoller div {
    animation: ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .ldsRoller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: -3px 0 0 -3px;
    background: rgba(255, 255, 255, 0.4);
  }
  .ldsRoller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .ldsRoller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .ldsRoller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .ldsRoller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .ldsRoller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .ldsRoller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .ldsRoller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .ldsRoller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .ldsRoller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .ldsRoller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .ldsRoller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .ldsRoller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .ldsRoller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .ldsRoller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .ldsRoller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .ldsRoller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes ldsRoller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
