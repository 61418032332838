$primary-color: #3366cc;
$secondary-color: rgba(51, 102, 204, 0.8);
$light-color: rgba(255, 255, 255, 0.7);

$white-color: white;
$gray-color: #262625;

$loader-speed: 0.5s;

@keyframes reduce {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
