@import './mixins/allimports.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 100%;

    padding: 5px;
    text-align: center;
    margin-bottom: 30px;
    background-color: $secondary-color;
  }
}

@include small {
  .container {
    .content {
      padding: 8px;
      margin-bottom: 35px;
    }
  }
}
@include medium {
  .container {
    cursor: pointer;

    .content {
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      p {
        font-size: 2.4rem;
      }
    }

    &:hover {
      .content {
        opacity: 1;
      }
    }

    @media (hover: none) {
      .content {
        height: auto;
        width: 100%;

        margin-bottom: 25px;
        opacity: 1;
        transition: none;

        p {
          font-size: 1.8rem;
        }
      }
    }
  }
}
