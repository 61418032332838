@import './mixins/allimports.scss';

:global .languageButton {
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  .bar {
    width: 2px;
    height: 10px;
    background: #3366cc;
    margin: 0px 4px;
  }

  p {
    cursor: pointer;
    color: #3366cc;
    font-size: 1.3rem;
    opacity: 0.5;

    &.active {
      color: #3366cc;
      opacity: 1;
    }
  }
}

:global .homeButton {
  z-index: 9999;
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 5px 10px;
  background-color: rgba(51, 102, 204, 0.8);
}

:global main {
  width: 100%;
  height: 100%;
}

:global .left-container {
  width: 100%;
  height: (100% / 6) * 2;

  .partner-block {
    height: 50%;

    &:nth-child(1) {
      border: none;
    }

    &:nth-child(2) {
      border: none;
      border-bottom: 2.5px solid $white-color;
    }
  }
}

:global .mid-container {
  width: 100%;
  height: (100% / 6);

  .partner-block {
    width: 100%;
    height: 100%;

    border-top: 2.5px solid $white-color;
    border-bottom: 2.5px solid $white-color;
  }
}

:global .right-container {
  width: 100%;
  height: (100% / 6) * 3;

  .partner-block {
    width: 100%;
    height: (100% / 3);

    border-top: 2.5px solid $white-color;
    border-bottom: 2.5px solid $white-color;

    &:nth-child(3) {
      display: none;
    }

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}

:global .title {
  color: $white-color;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.8rem;

  &.blue {
    color: $primary-color;
  }

  &.light {
    font-weight: 500;
  }

  &.small-margin {
    margin-bottom: 3px;
  }

  &.margin {
    margin-bottom: 5px;
  }
}

:global .large-title {
  color: $white-color;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.25rem;

  &.blue {
    color: $primary-color;
  }

  &.margin {
    margin-bottom: 20px;
  }
}

:global .small-title {
  color: $primary-color;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.8rem;

  &.margin {
    margin-bottom: 5px;
  }

  &.white {
    color: $white-color;
  }

  &.light {
    font-weight: 500;
  }
}

:global .subtitle {
  color: $white-color;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

:global .small-text {
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

:global .text {
  color: $gray-color;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: capitalize;
}

@include small {
}
@include medium {
  :global .left-container {
    height: (100% / 5);

    .partner-block {
      display: inline-block;
      vertical-align: middle;

      width: 50%;
      height: 100%;
    }
  }

  :global .mid-container {
    height: (100% / 5) * 2;
  }

  :global .right-container {
    height: (100% / 5) * 2;

    .partner-block {
      display: inline-block;
      vertical-align: middle;

      width: 50%;
      height: 50%;

      &:nth-child(1) {
        border-right: 2.5px solid $white-color;
      }

      &:nth-last-child(1) {
        width: 100%;
      }
    }
  }
}
@include large {
  :global .left-container {
    display: inline-block;
    vertical-align: middle;

    width: (100% / 3);
    height: (100% / 5) * 2;

    .partner-block {
      display: block;

      width: 100%;
      height: 50%;

      &:nth-child(2) {
        border-right: 2.5px solid $white-color;
      }
    }
  }

  :global .mid-container {
    display: inline-block;
    vertical-align: middle;

    width: (100% / 3) * 2;
    height: (100% / 5) * 2;

    .partner-block {
      border-top: none;
      border-left: 2.5px solid $white-color;
    }
  }

  :global .right-container {
    height: (100% / 5) * 3;

    .partner-block {
      width: (100% / 3);
      height: (100% / 3);

      &:nth-child(2) {
        border: 2.5px solid $white-color;
      }

      &:nth-child(3) {
        display: inline-block;
        vertical-align: middle;

        border-left: 2.5px solid $white-color;
      }

      &:nth-last-child(1) {
        width: 100%;
        height: (100% / 3) * 2;
      }
    }
  }

  :global .title {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  :global .small-title {
    font-size: 1.9rem;
    line-height: 1.9rem;
  }

  :global .subtitle {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }
}
@include extra-large {
}
