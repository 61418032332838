@import './mixins/allimports.scss';

:global .partner-notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.4);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  &-hidden {
    opacity: 0;
    pointer-events: none;
  }

  &-inner {
    position: sticky;
    width: 90%;
    background: $primary-color;
    text-align: center;
    border: 3px solid white;
    padding: 25px 15px;

    .button {
      position: absolute;
      top: 10px;
      right: 6px;
      cursor: pointer;

      .bar {
        width: 20px;
        height: 3px;
        background: white;

        &:nth-child(1) {
          transform: rotate(45deg) translateX(2px) translateY(2px);
        }

        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }

    .small-title {
      color: white !important;
      margin-bottom: 14px;
    }

    .progress {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 3px;
      background: white;

      animation: reduce 7.5s linear 0s 1 forwards normal;
    }
  }
}
@include small {
  :global .partner-notification {
    &-inner {
      width: 80%;
      max-width: 500px;
      padding: 35px 20px;

      .button {
        top: 14px;
        right: 8px;

        .bar {
          width: 20px;
          height: 3px;
          background: white;

          &:nth-child(1) {
            transform: rotate(45deg) translateX(2px) translateY(2px);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}

@include medium {
  :global .partner-notification {
    &-inner {
      padding: 45px 25px;

      .small-title {
        color: white !important;
        margin-bottom: 20px;
      }
    }
  }
}

@include large {
  :global .partner-notification {
    &-inner {
      padding: 55px 30px;
    }
  }
}

@include extra-large {
}
