@import './mixins/allimports.scss';

:global main {
  width: 100%;
  height: 100%;
}

:global .main {
  width: 100%;
  height: 100%;
}

:global .image-show {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

:global .block {
  width: 200px;
  height: 200px;
  display: inline-block;
  vertical-align: middle;

  border-bottom: 2.5px solid $white-color;
  border-top: 2.5px solid $white-color;

  & a {
    text-decoration: none;
  }

  &:nth-child(1) {
    border: none;
  }

  &:nth-child(2) {
    border: none;
  }

  &:nth-child(3) {
    border-top: none;
    border-bottom: 2.5px solid $white-color;
  }
}

:global .title {
  color: $white-color;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.8rem;

  &.blue {
    color: $primary-color;
  }

  &.light {
    font-weight: 500;
  }

  &.small-margin {
    margin-bottom: 3px;
  }

  &.margin {
    margin-bottom: 5px;
  }
}

:global .large-title {
  color: $white-color;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.25rem;

  &.blue {
    color: $primary-color;
  }

  &.small-margin {
    margin-bottom: 10px;
  }

  &.margin {
    margin-bottom: 20px;
  }

  &.heavy {
    font-weight: 800;
  }
}

:global .small-title {
  color: $primary-color;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 1.8rem;

  &.margin {
    margin-bottom: 5px;
  }

  &.white {
    color: $white-color;
  }

  &.light {
    font-weight: 500;
  }
}

:global .subtitle {
  color: $white-color;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.3rem;
  line-height: 1.5rem;
}

:global .small-text {
  color: $gray-color;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

:global .text {
  color: $gray-color;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: capitalize;
}

:global .partner-text {
  color: $white-color;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.5rem;

  &.margin {
    margin-bottom: 12px;
  }

  &.small-margin {
    margin-bottom: 5px;
  }

  &.small {
    font-size: 1rem;
  }

  &.blue {
    color: $primary-color;
  }

  &.heavy {
    font-weight: 800;
  }
}

@include small {
}
@include medium {
  :global .block {
    &:nth-child(1) {
      border: none !important;
      border-right: 2.5px solid $white-color !important;
      border-bottom: 2.5px solid $white-color !important;
    }

    &:nth-child(3) {
      border: none !important;
      border-left: 2.5px solid $white-color !important;
      border-bottom: 2.5px solid $white-color !important;
    }

    &:nth-child(even) {
      border-right: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;
      border-top: 2.5px solid $white-color;
    }

    &:nth-child(odd) {
      border-left: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;
      border-top: 2.5px solid $white-color;
    }
  }
}
@include large {
  :global .block {
    &:nth-child(1) {
      border: none !important;
      border-right: 2.5px solid $white-color !important;
      border-bottom: 2.5px solid $white-color !important;
    }

    &:nth-child(3) {
      border: none !important;
      border-left: 2.5px solid $white-color !important;
      border-right: 2.5px solid $white-color !important;
      border-bottom: 2.5px solid $white-color !important;
    }

    &:nth-child(4) {
      border: none !important;
      border-left: 2.5px solid $white-color !important;
      border-bottom: 2.5px solid $white-color !important;
    }

    &:nth-child(3n + 2) {
      border: none;
      border-right: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;
      border-top: 2.5px solid $white-color;
    }

    &:nth-child(3n + 0) {
      border: 2.5px solid $white-color;
    }

    &:nth-child(3n + 1) {
      border: none;
      border-left: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;
      border-top: 2.5px solid $white-color;
    }
  }

  :global .title {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  :global .small-title {
    font-size: 1.9rem;
    line-height: 1.9rem;
  }

  :global .subtitle {
    font-size: 1.4rem;
    line-height: 1.5rem;
  }

  :global .partner-text {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
}
@include extra-large {
}
