@import './mixins/allimports.scss';

.filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  .checkbox {
    width: 14px;
    height: 14px;
    border: 2px solid $primary-color;
    margin-right: 7px;
    margin-top: 8px;
    margin-bottom: 8px;

    transition: background 0.15s ease-in-out;

    &.active {
      background: $primary-color;
    }
  }
}
