@import './mixins/allimports.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.content {
  width: 100%;
  height: 100%;

  background-color: $secondary-color;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .innerContent {
    text-align: center;

    .socialRow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 12px;

      svg {
        margin: 0px 5px;
        margin-top: 3px;
        width: 15px;
        height: 15px;

        path,
        circle {
          fill: $white-color;
          stroke: $primary-color;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2;
        }
      }
    }

    .contentRow {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 7px;

      .subrow {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      svg {
        margin-right: 10px;
        width: 20px;
        height: 20px;

        path,
        circle {
          fill: $white-color;
          stroke: $primary-color;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2;
        }
      }
    }
  }
}
