@import './mixins//allimports.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $light-color;

  .content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .control {
      cursor: pointer;
      z-index: 20;
      position: absolute;
      width: 45px;
      height: 100%;
      padding: 0 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      top: 50%;
      transform: translateY(-50%);

      svg {
        width: 25px;
        height: 25px;

        path {
          fill: $white-color;
        }
      }

      &:hover {
        opacity: 0.7;
      }

      &.left {
        left: 0;

        svg {
          transform: rotate(180deg);
        }
      }
      &.right {
        right: 0;
      }
    }

    .bulletContainer {
      position: absolute;
      z-index: 20;
      bottom: 5px;
      left: 0;
      width: 100%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .bullet {
        margin: 0 1.5px;
        width: 6px;
        height: 6px;
        border-radius: 100%;

        background: rgba(255, 255, 255, 0.4);
        transition: background 0.2s ease-in-out;

        &.active {
          background: rgba(255, 255, 255, 0.9);
        }
      }
    }

    .imageContainer {
      height: 100%;
      transition: margin-left 0.5s ease-in-out;

      .innerContainer {
        height: 100%;
        position: relative;
        display: inline-block;

        .image {
          position: relative;
          z-index: 1;
          width: 100%;
          height: 100%;

          transition: opacity $loader-speed ease-in-out;

          top: 0;
          left: 0;
        }
      }
    }
  }
}

@keyframes hide {
  from {
    bottom: 0;
  }
  to {
    bottom: -50px;
  }
}
