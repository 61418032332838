@import './mixins/allimports.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $primary-color;

  &.light {
    background-color: $light-color !important;
  }

  .content {
    width: 100%;
    max-width: 400px;
    height: 100%;

    margin: auto;
    padding: 20px;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
