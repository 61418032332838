@import './mixins/allimports.scss';

.contact {
  .highlight {
    width: 100%;

    .container {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: $primary-color;

      &.light {
        background-color: $light-color !important;
      }

      .content {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .left {
    height: (100% / 7) * 3;

    .highlight {
      height: (100% / 3);

      border-top: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;
    }

    .largeBlock {
      height: (100% / 3) * 2;

      @include background-2x('../../images/watertoren', 'jpg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center right;

      border-top: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;
    }
  }

  .right {
    height: (100% / 7) * 4;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @include background-2x('../../images/sfeerbeeld', 'jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .highlight {
      height: (100% / 4);
      border-top: 2.5px solid $white-color;
      border-bottom: 2.5px solid $white-color;

      &.small {
        height: (100% / 4) / 3 !important;
      }

      .list {
        margin-top: 5px;

        .listItem {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 3px;

          svg {
            margin-right: 10px;
            width: 14px;
            height: 14px;

            path {
              fill: $primary-color;
            }
          }
        }
      }
    }

    .form {
      height: (100% / 4);
      width: 100%;
      border-top: 2.5px solid $white-color;

      .container {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $primary-color;

        &.light {
          background-color: $light-color !important;
        }

        .content {
          width: 100%;
          height: 100%;
          padding: 25px;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }
    }
  }
}

@include small {
}
@include medium {
  .contact {
    .highlight {
      width: 50%;
    }

    .left {
      height: (100% / 6) * 3;
      position: relative;

      .highlight {
        position: absolute;
        height: (100% / 3);
        top: 0;
        left: 0;

        border-top: 2.5px solid $white-color;
        border-bottom: 2.5px solid $white-color;
        border-right: 2.5px solid $white-color;
      }

      .largeBlock {
        height: 100%;
      }
    }

    .right {
      height: (100% / 6) * 3;

      .highlight {
        height: (100% / 3);

        &:nth-child(1) {
          order: 2;
          border: none;
          border-top: 2.5px solid $white-color;
          border-bottom: 2.5px solid $white-color;
          border-left: 2.5px solid $white-color;
        }

        &:nth-child(2) {
          order: 1;
          border: none;
          border-top: 2.5px solid $white-color;
          border-bottom: 2.5px solid $white-color;
          border-right: 2.5px solid $white-color;
        }

        &:nth-child(3) {
          order: 3;
          border: none;
          border-top: 2.5px solid $white-color;
          border-bottom: 2.5px solid $white-color;
          border-right: 2.5px solid $white-color;
        }

        &:nth-child(4) {
          order: 4;
          border: none;
          border-top: 2.5px solid $white-color;
          border-bottom: 2.5px solid $white-color;
          border-left: 2.5px solid $white-color;
        }

        &:nth-child(5) {
          order: 6;
          border: none;
          border-top: 2.5px solid $white-color;
          border-left: 2.5px solid $white-color;
        }

        &.small {
          height: (100% / 3) !important;
        }

        .list {
          margin-top: 8px;
        }
      }

      .form {
        order: 5;
        width: 50%;
        height: (100% / 3);

        border: none;
        border-top: 2.5px solid $white-color;
        border-right: 2.5px solid $white-color;
      }
    }
  }
}
@include large {
  @include medium {
    .contact {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .left {
        width: (100% / 3);
        height: 100%;

        .highlight {
          position: static;
          height: (100% / 3);
          width: 100%;

          border: none;
          border-top: 2.5px solid $white-color;
          border-bottom: 2.5px solid $white-color;
          border-right: 2.5px solid $white-color;
        }

        .largeBlock {
          height: (100% / 3) * 2;

          border: none;
          border-top: 2.5px solid $white-color;
          border-right: 2.5px solid $white-color;
        }
      }

      .right {
        width: (100% / 3) * 2;
        height: 100%;

        .highlight {
          height: (100% / 3);

          &:nth-child(1) {
            order: 2;
            border: none;
            border-top: 2.5px solid $white-color;
            border-bottom: 2.5px solid $white-color;
            border-left: 2.5px solid $white-color;
          }

          &:nth-child(2) {
            order: 1;
            border: 2.5px solid $white-color;
          }

          &:nth-child(3) {
            order: 3;
            border: 2.5px solid $white-color;
          }

          &:nth-child(4) {
            order: 4;
            border: none;
            border-top: 2.5px solid $white-color;
            border-bottom: 2.5px solid $white-color;
            border-left: 2.5px solid $white-color;
          }

          &:nth-child(5) {
            order: 6;
            border: none;
            border-top: 2.5px solid $white-color;
            border-left: 2.5px solid $white-color;
          }

          &.small {
            height: (100% / 3) !important;
          }

          .list {
            margin-top: 8px;
          }
        }

        .form {
          order: 5;
          width: 50%;
          height: (100% / 3);

          border: none;
          border-top: 2.5px solid $white-color;
          border-right: 2.5px solid $white-color;
        }
      }
    }
  }
}
