@import './mixins/allimports.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
  }
}
