@import '../../styles/mixins/allimports.scss';

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 2px 8px;
  text-align: right;

  .text {
    font-weight: 500;
    font-size: 8px;
    color: $white-color;
    font-style: italic;
  }
}
