@import './mixins/allimports.scss';

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 25px;
}
