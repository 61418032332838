@import './mixins/allimports.scss';

.search {
  width: 100%;
  background-color: $primary-color;
  padding: 10px;

  .searchIcon {
    display: none;
  }

  .wrapper {
    p {
      display: none;
    }

    .searchBar {
      width: 100%;
      height: 28px;
      padding: 3px 5px;
      font-weight: 300;
      font-size: 16px;
      color: #262625;
      text-align: center;

      border: none;
      border-radius: 0;
      -webkit-appearance: none;
    }

    .filters {
      display: none;
    }
  }
}

@include small {
}

@include medium {
  .search {
    display: none !important;
  }

  .search {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: -325px;

    width: 350px;
    height: 100%;
    padding: 0;
    background: none;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    transition: left 0.2s ease-in-out;

    &.open {
      left: 0 !important;
    }

    .wrapper {
      flex-grow: 1;
      height: 100%;

      border-right: 3px solid white;
      background: rgba(255, 255, 255, 0.9);
      padding: 100px 25px 25px 25px;
      overflow-y: auto;

      p {
        display: block;
      }

      .searchBar {
        padding: 2px 5px;
        font-size: 14px;

        border: 1px solid $primary-color;
      }

      .filters {
        display: block;
        margin-top: 40px;
      }
    }

    .searchIcon {
      display: inline-block;
      width: 25px;
      height: 40px;
      margin-top: 120px;

      border-right: 3px solid white;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
      background: rgba(255, 255, 255, 0.8);
      cursor: pointer;
      padding: 2px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $primary-color;
        }
      }
    }
  }
}
