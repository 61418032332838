@import './mixins/allimports.scss';

.ie {
  .partner-fix-ie {
    cursor: pointer;

    .content {
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      p {
        font-size: 2.4rem;
      }
    }

    &:hover {
      .content {
        opacity: 1;
      }
    }
  }
}

.webp {
  .contact-image-1 {
    background-image: url('../images/watertoren.webp') !important;
  }

  .contact-image-2 {
    background-image: url('../images/sfeerbeeld.webp') !important;
  }
}

// .jpegxr {
//   .contact-image-1 {
//     @include background-2x('../../images/watertoren', 'jxr', '!important');
//   }

//   .contact-image-2 {
//     @include background-2x('../../images/sfeerbeeld', 'jxr', '!important');
//   }
// }

// .jpeg2000 {
//   .contact-image-1 {
//     @include background-2x('../../images/watertoren', 'j2k', '!important');
//   }

//   .contact-image-2 {
//     @include background-2x('../../images/sfeerbeeld', 'j2k', '!important');
//   }
// }
