@import './mixins/allimports.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: 62.5%;
}

a {
  text-decoration: none;
}

html {
  width: 100%;
  height: 100%;
  background-color: $white-color;
}

body {
  width: 100%;
  height: 100%;
}

#root {
  position: relative;
}

.toast-message {
  width: 95%;
  max-width: 340px;
  margin: 2.5%;

  @include medium {
    margin: 12px;
  }

  .Toastify__toast {
    min-height: 40px;

    .Toastify__close-button--error {
      color: #721c24;
    }

    .Toastify__close-button--success {
      color: #155724;
    }
  }

  .Toastify__toast-body {
    p,
    strong {
      font-size: 1.2rem;
    }
  }

  .Toastify__toast--error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .Toastify__toast--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;

  transform: scale(0.35);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include small {
  * {
    font-size: 68.75%;
  }
}

@include extra-large {
  * {
    font-size: 75%;
  }
}
